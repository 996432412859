import React, { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';
//import NotFound from "./NotFoundPage";
import ReactGrid from "./React-Grid";
import ReactBootstrapModal from './UI/ReactBootstrapModal';
import Button from 'react-bootstrap/Button'; 
import MobileTableInfo from "./UI/MobileTableInfo";
import { createGridColumns } from "../Utils/utils";
import * as httpService from '../services/HTTPService';
import WOW from 'wowjs';
import HeaderSocialLinks from "./HeaderSocialLinks";
import { Chart } from "react-google-charts";
import { AriaIntervalChart } from "./UI/ReactGoogleAriaChart";
import headerSocialLinksArray from "./HeaderSocialLinks";

const TokenDetails = () => {
    const location = useLocation();
    const [rowData, setRowData]  = useState(location.state ? location.state.rowData : "");
    const [topRowData, setTopRowData]  = useState(location.state ? location.state.rowData : "");
    const [AVG_CallImmediateEffect, setAVG_CallImmediateEffect]  = useState(rowData !== "" ? rowData[0].AVG_CallImmediateEffect : "");
    const [AVG_FromCallToPeak, setAVG_FromCallToPeak] = useState(rowData !== "" ? rowData[0].AVG_FromCallToPeak : "");
    const [AVG_TracingImpact, setAVG_TracingImpact] = useState(rowData !== "" ? rowData[0].AVG_TracingImpact : "");
    const group = window.location.href.split('/').pop();
    const decodedUrlGroup = decodeURIComponent(group);
    const nameOfCallGroup  = location.state ? location.state.callGroup : decodedUrlGroup;
    const [modalShow, setModalShow] = React.useState(false);
    let numberColumn = {valueGetter: "node.rowIndex + 1", headerName: "#", width: 60, sortable: false, suppressSorting: true};
    // , 'OrderNumber'
    // 'NameOfCallGroup',
    let initialColumns = createGridColumns(['number', 'CryptoGroupImage', 'TokenName', 'BuyLink', 'TotalMarketCapIN', 'TotalMarketCap', 'ImpactOfCall', 'SuccessRate', 'FromCalltoPeak']);
    let [columnDefs, setColumnDefs] = useState(initialColumns);
    let extraColumns = createGridColumns(['TokenHash', 'Chain', 'PriceUSD', 'VolumeGenereted_h24', 'Holders', 'CryptoTokenCall', 'Source', 'Timestamp']);
    const [cryptoGroupImageUrl, setCryptoGroupImageUrl] = useState('');
    const [cryptoGroupInfo, setCryptoGroupInfo] = useState('');
    const [socialLinks, setSocialLinks] = useState([]);
    const [chartData, setChartData] = useState([]);
    const getData = () => {
        httpService.getSingleItem(nameOfCallGroup).then((res) => {
            if(res && res.length > 0) {
                if(res[0].CryptoGroupImage && res[0].CryptoGroupImage !== '') {
                    let imgSrc = res[0].CryptoGroupImage;
                    const folderName = '/callgrimages/';
                    
                    if(imgSrc === null || imgSrc.startsWith('https://shillguard-001')) {
                        // TOP 5 Callers
                        const imgName = imgSrc.split('/').pop();
                        const img = folderName + imgName;
                        imgSrc = img;
                    }

                    setCryptoGroupImageUrl(imgSrc);
                }
                if(res[0].CryptoGroupInfo) {
                    setCryptoGroupInfo(res[0].CryptoGroupInfo);
                }
                let mainData = [];
                let topData = [];
                for(var i = 0; i < res.length; i++) {
                    const cryptoGroup = res[i];
                    if(cryptoGroup.OrderNumber === 0) {
                        mainData.push(cryptoGroup);
                    }
                    else{
                        topData.push(cryptoGroup);
                    }
                }

                if(socialLinks.length === 0) {
                    const arr = [ 
                        {
                            link: res[0].TwitterLink,
                            icon: '/img/social/header-social/X_20x20.svg',
                            whiteIcon: '/img/social/header-social/X_20x20_white.svg',
                            class: 'tw-link',
                            name: 'Twitter',
                            text: 'Follow us on Twitter to stay in the loop with real-time crypto trends, Shillguard news, and engaging discussions.',
                        },
                        {
                            link: res[0].TelegramLink,
                            icon: '/img/social/header-social/Telegram_20x20.svg',
                            whiteIcon: '/img/social/header-social/Telegram_20x20_white.svg',
                            class: 'tel-link',
                            name: 'Telegram',
                        }
                    ]
                    setSocialLinks(arr);
                }

                //const tData = res.filter(g => g.OrderNumber !== 0).sort((a, b) => a.OrderNumber - b.OrderNumber);
                //const uniqueData = [...new Map(tData.map(item => [item['OrderNumber'], item])).values()];
                  
                //setTopRowData(tData);
                //setRowData(res);
                const orderedTopData = topData.sort((a, b) => a.OrderNumber - b.OrderNumber);
                setTopRowData(orderedTopData);
                setRowData(mainData);
                setAVG_CallImmediateEffect(res[0].AVG_CallImmediateEffect);
                setAVG_FromCallToPeak(res[0].AVG_FromCallToPeak);
                setAVG_TracingImpact(res[0].AVG_TracingImpact);
            }
        })
    }

    const modifyColumns = (data) => {
        const storageColumns = localStorage.getItem('tokenDetailsColumns');
        if(storageColumns) {
          if(data.length > 0) {
            setColumnDefs(data);
          }
        }
        else {
          setColumnDefs(initialColumns);
        }
        setModalShow(false);
      }
    //const groupSuccessColumns = createGridColumns(['number', 'ImpactOfCall', 'SuccessRate', 'FromCalltoPeak']);

    let [visible, setVisible] = useState('');

    const showGroupSuccessInfo = () => {
        if(visible === '') {
            setVisible('visible');
            visible = 'visible';
        }
        else{
            setVisible('');
            visible = '';
        }
    }

    const imgSrc = (base64Str) => {
        let src = "";
        if(rowData !== "") {
            let str = base64Str.replace(/(\r\n|\n|\r)/gm, "");
            if(!str.startsWith('data:image', 10)) {
                str = "data:image/png;base64, " + base64Str.replace(/(\r\n|\n|\r)/gm, "");
            }
            src = str;
        }
        if(base64Str.startsWith('http')) {
            return base64Str;
        }
        return src;
    }

    const hideInfo = (e) => {
        var popups = document.querySelectorAll('.info-popup');
        for(var i = 0; i < popups.length; i++) {
            if(popups[i].style.display === 'block') {
                popups[i].style.display = 'none';
            }
            if(popups[i].classList.contains('visible')) {
                popups[i].classList.remove('visible');
                setVisible('');
                visible = '';
            }
        }
    }

    useEffect(() => {
        getData();

        new WOW.WOW({
            live: false
        }).init();

        document.addEventListener('click', (e) => {
            // if(e.target.classList.contains('info-icon')) {
            //     showInfo(e);
            // }
            if(e.target.classList.contains('close-btn')) {
                hideInfo(e);
            }
            if(!e.target.classList.contains('info-popup') && !e.target.classList.contains('info-icon')) {
                hideInfo(e);
            }
        })

        const storageColumns = localStorage.getItem('tokenDetailsColumns');
        if(storageColumns && storageColumns !== "null") {
            let additionalColumns = JSON.parse(storageColumns);
            //let columns = [...additionalColumns];
            const storageData = createGridColumns(additionalColumns)
            setColumnDefs(storageData);
        }
        else {
            if(!columnDefs[0].valueGetter) {
                columnDefs[0] = numberColumn;
            }
            setColumnDefs(columnDefs);
        }

        httpService.getChartData(nameOfCallGroup).then((res) => {
            if(res) {
                console.log("data: ", res)
                setChartData(res);
            }
        })

    }, [])
    

    useEffect(() => {
        const interval = setInterval(() => {
            getData();
          }, 30000);
          return () => clearInterval(interval);
    }, [])

    return (
        <div className="token-details">
            {/* <h1 className="wow fadeIn" data-wow-duration="0.2s">{nameOfCallGroup}</h1>
            <div className="token-metrics wow fadeIn" data-wow-duration="0.2s">
                {cryptoGroupImageUrl && cryptoGroupImageUrl !== '' ? (
                    <div className="cryptoGroup-img">
                        <img 
                            style={{'width': '120px', 'margin': '20px 0 10px 0', 'borderRadius': '70px'}} 
                            src={imgSrc(cryptoGroupImageUrl)} 
                            alt ="cryptoGroupImage" 
                        />
                    </div>
                ) : null} 
                
                {cryptoGroupInfo && cryptoGroupInfo !== '' ? (
                    <div className="cryptoGroup-desc">
                        <p>{cryptoGroupInfo}</p>
                    </div>
                ) : null} 
                
            </div>
            <div className="group-table wow fadeIn" style={{'margin': '0 0 40px 0', 'position': 'relative'}} data-wow-duration="0.2s">
                <div className="group-table-inner">
                    <h3 style={{'margin': '20px 0 20px 0'}}>Group Success Rate<span onMouseLeave={hideInfo} onMouseOver={showGroupSuccessInfo} onClick={showGroupSuccessInfo} className="info-icon" style={{'padding': '2px 8px', 'fontSize': '14px', 'top': '-4px', 'position': 'relative', 'cursor': 'pointer'}}>i</span></h3>
                    <div  className={`info-popup ${visible}`}>
                        <div className="modal-dialog modal-lg modal-dialog-centered">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <div className="modal-title h4" id="contained-modal-title-vcenter"></div>
                                    <button type="button" className="btn btn-primary" onClick={hideInfo}>X</button>
                                    <button type="button" className="btn-close" aria-label="Close"></button>
                                </div>
                                <span>
                                    <strong>Group Success Rate</strong> provides an overview of the average performance of each call group or influencer. 
                                    It includes three key metrics: the average immediate effect of their calls, 
                                    the average tracing impact over time, and the average percentage change from the call to 
                                    the all-time peak. This information helps assess the historical success and impact of each group's cryptocurrency calls.
                                </span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="group-table-grid wow fadeIn" data-wow-duration="0.3s">
                    <table className="group-table-grid-tb">
                        <thead>
                            <tr>
                                <th>Call Immediate Effect</th><th>Tracing Impact</th><th>From Call to Peak</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                {parseFloat(AVG_CallImmediateEffect) < 0 ? (
                                    <td className="red-value-label">{AVG_CallImmediateEffect}%</td>
                                ) : (
                                    <td className="green-value-label">{AVG_CallImmediateEffect}%</td>
                                )}
                                {parseFloat(AVG_TracingImpact) < 0  ? (
                                    <td className="red-value-label">{AVG_TracingImpact}%</td>
                                ) : (
                                    <td className="green-value-label">{AVG_TracingImpact}%</td>
                                )}
                                {parseFloat(AVG_FromCallToPeak) < 0 ? (
                                    <td className="red-value-label">{AVG_FromCallToPeak}%</td>
                                ) : (
                                    <td className="green-value-label">{AVG_FromCallToPeak}%</td>
                                )}
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div> */}

            <div className="flex-box token-d">
                <div className="flex-box">
                    <div className="cryptoGroup-img">
                        {cryptoGroupImageUrl !== '' ? (
                            <img 
                            style={{'width': '120px', 'margin': '20px 0 10px 0', 'borderRadius': '70px'}} 
                            src={cryptoGroupImageUrl} 
                            alt ="cryptoGroupImage" 
                        />
                        ) : null}
                    </div>
                    <div>
                        <h1>{nameOfCallGroup}</h1>
                        {cryptoGroupInfo && cryptoGroupInfo !== '' ? (
                            <div>
                                <p style={{margin: '0'}}>{cryptoGroupInfo}</p>
                            </div>
                        ) : null} 
                        {socialLinks.length > 0 ? (
                            <HeaderSocialLinks links={socialLinks} />
                        ) : null}
                    </div>
                </div>
                <div className="flex-box chart-el">
                    <div>
                        <h3>Group Success Rate</h3>
                        <table style={{width: '100%'}}>
                            <thead style={{'display': 'none'}}>
                                <tr>
                                    <th>CallEfc</th><th>Trace</th><th>Peak</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>CallEfc</td>
                                    {parseFloat(AVG_CallImmediateEffect) < 0 ? (
                                        <td className="red-value-label">{AVG_CallImmediateEffect}%</td>
                                    ) : (
                                        <td className="green-value-label">{AVG_CallImmediateEffect}%</td>
                                    )}
                                </tr>
                                <tr>
                                    <td>Trace</td>
                                    {parseFloat(AVG_TracingImpact) < 0  ? (
                                        <td className="red-value-label">{AVG_TracingImpact}%</td>
                                    ) : (
                                        <td className="green-value-label">{AVG_TracingImpact}%</td>
                                    )}
                                </tr>
                                <tr>
                                    <td>Peak</td>
                                    {parseFloat(AVG_FromCallToPeak) < 0 ? (
                                        <td className="red-value-label">{AVG_FromCallToPeak}%</td>
                                    ) : (
                                        <td className="green-value-label">{AVG_FromCallToPeak}%</td>
                                    )}
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    {chartData && chartData.length > 0 ? (
                        <div className="td-chart">
                            <AriaIntervalChart chartData={chartData} />         
                        </div>
                    ) : null}
                    
                </div>
            </div>
            

            <div>
                <div className="wow fadeIn" data-wow-duration="0.7s" style={{"textAlign": "right"}}>
                    <MobileTableInfo />
                    <div className='desktop'>
                        <div className="descktop" style={{marginTop: "30px"}}></div>
                        <Button variant="primary" onClick={() => setModalShow(true)} style={{'marginBottom': '15px', 'verticalAlign': 'top'}}>
                            <svg style={{"marginRight": "10px"}} xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" color="var(--c-color-gray-500)"><path d="M19 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3ZM13 5V19H9V5H13ZM5 5H7V19H5V5ZM15 19V5H19V19H15Z" fill="currentColor"></path></svg>
                            Customize
                        </Button>
                    </div>
                </div>
                <ReactBootstrapModal 
                    show={modalShow} 
                    onHide={() => setModalShow(false)} 
                    columns={columnDefs} 
                    initialColumns={initialColumns}
                    modifycolumns={modifyColumns}
                    extraColumns={extraColumns}
                    isHomePage={false}
                />
                <div className="token-grids wow fadeIn" data-wow-duration="1.5s">
                    <div className="topCallsGrid" >
                        <h3>All-Time Top Performers</h3>
                        <ReactGrid tooltipInteraction={true} columns={columnDefs} sortable={false} rowData={topRowData} errorMessage={''} />
                    </div>
                    <div>
                        <h3>Live Token Call Feed</h3>
                        <ReactGrid tooltipInteraction={true} columns={columnDefs} sortable={true} rowData={rowData} errorMessage={''} />
                    </div>
                    <div className='mob-900 cutomize-mob' style={{marginTop: "30px"}}>
                        <Button variant="primary" onClick={() => setModalShow(true)} style={{'marginBottom': '15px', 'verticalAlign': 'top'}}>
                            <svg style={{"marginRight": "10px"}} xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" color="var(--c-color-gray-500)"><path d="M19 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3ZM13 5V19H9V5H13ZM5 5H7V19H5V5ZM15 19V5H19V19H15Z" fill="currentColor"></path></svg>
                            Customize
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TokenDetails;