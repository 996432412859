import React, { useState } from "react";
import LogoAndCompName from "./UI/LogoAndCompName";
import HeaderSocialLinks from "./HeaderSocialLinks";
import { headerSocialLinksArray } from "../data/HeaderSocialLinksData";
import { LegalAdvisePageHtml } from "./FooterPages";
import { TermsOfusePageHtml } from "./FooterPages";
import { ContactsPageHtml } from "./FooterPages";
import Banner from "./UI/AdBanner";

const Footer = () => {
    const [pageContent, setPageContent] = useState('');

    const openFooterPage = (e, content) => {
        if(document.querySelector('.blue-link')) {
            document.querySelector('.blue-link').classList.remove('blue-link');
        }
        e.target.classList.add('blue-link');
        setPageContent(content);
        if(document.querySelector('.footer-outer')) {
            document.querySelector('.footer-outer').scrollIntoView()
        }
    }

    const closeFooterPage = () => {
        if(document.querySelector('.blue-link')) {
            document.querySelector('.blue-link').classList.remove('blue-link');
        }
        setPageContent('');
    }

    const FooterBanner = () => {
        return (
            <a href="https://raidsharksbot.com/" target="_blank">
                <img alt="footer_banner" src="/img/raidsharks-50-rev.gif" loading="lazy" /> 
            </a>
        )
    }

    return (
        <div className="footer-bg">
            <div className="footer-outer">
                <div className="app-footer">
                    {pageContent !== '' ? (
                        <div className="f-p-content">
                            <div className="f-p-inner">
                                <span className="close-f-p" onClick={closeFooterPage}></span>
                                {pageContent !== '' && pageContent === 'Legal' ? (<LegalAdvisePageHtml />) : null}
                                {pageContent !== '' && pageContent === 'Terms' ? (<TermsOfusePageHtml />) : null}
                                {pageContent !== '' && pageContent === 'Contact' ? (<ContactsPageHtml />) : null}
                            </div>
                        </div>
                    ) : null}
                    
                    <div className="footer-lists flex-box" >
                        <div className="desktop footer-col">
                            <LogoAndCompName />
                            <div style={{marginTop: '20px'}}>
                                {/* <HeaderSocialLinks links={headerSocialLinksArray} /> */}
                            </div>
                        </div>
                        <div className="footer-col">
                            <nav>
                                <ul className="footerLinks">
                                    <li><a className="footer-link" href="/ourtoken">Our Token</a></li>
                                    <li><a className="footer-link" href="/news">Crypto News</a></li>
                                    <li><a className="footer-link" href="/about">About us</a></li>
                                    {/* <li><a className="footer-link" href="/terms">FAQ</a></li> */}
                                    <li className="footer-link" onClick={(e) => openFooterPage(e, 'Legal')}>Legal Advice</li>
                                </ul>
                            </nav>
                        </div>
                        <div className="footer-col">
                            <nav>
                                <ul className="footerLinks">
                                    {/* <li><a className="footer-link" href="/terms">Privacy Policy</a></li> */}
                                    <li className="footer-link" onClick={(e) => openFooterPage(e, 'Terms')}>Terms of use</li>
                                    
                                    <li><a className="footer-link" href="/terms">Cookies</a></li>
                                    <li className="footer-link" onClick={(e) => openFooterPage(e, 'Contact')}>Contact Us</li>
                                    <li><a className="footer-link" href="https://coinmarketcap.com/currencies/shill-guard-token/" target="_blank">Coinmarketcap</a></li>
                                    <li><a className="footer-link" href="https://www.coingecko.com/en/coins/shill-guard-token" target="_blank">Coingecko</a></li>
                                </ul>
                            </nav>
                        </div>
                        <div className="desktop footer-col">
                            {/* <nav>
                                <ul className="footerLinks">
                                    <li>Our Partners</li>
                                    <li>
                                        <a className="footer-link partners-link" href="https://coinsult.net/" target="_blank">
                                            <img src="/img/partners/coinsult_squx46.png" alt="Coinsult logo" />
                                            <span className="sm-txt">
                                                KYC Verified by 
                                                <span> Coinsult</span>
                                            </span>
                                        </a>
                                    </li>
                                    <li>
                                        <a className="footer-link partners-link" href="https://revolutionlab.bg/" target="_blank">
                                            <img src="/img/partners/logo_r_labx46.png" alt="Coinsult logo" />
                                            <span>Revolution Lab</span>
                                        </a>
                                    </li>
                                </ul>
                            </nav> */}
                            <HeaderSocialLinks links={headerSocialLinksArray} />
                            {/* <div>
                                <div className="desktop" style={{marginTop: "20px"}}></div>
                                <Banner />
                            </div> */}
                            <div style={{marginTop: "20px"}}>
                                <FooterBanner />
                            </div>
                        </div>
                        <div className="mob-900 footer-col">
                            <LogoAndCompName />
                            <div style={{marginTop: '20px'}}>
                                <HeaderSocialLinks links={headerSocialLinksArray} />
                            </div>
                            {/* <div>
                                <div className="desktop" style={{marginTop: "20px"}}></div>
                                <Banner />
                            </div> */}
                            <div style={{marginTop: "20px"}}>
                                <FooterBanner />
                            </div>
                        </div>
                    </div>
                    <p className="copy-right">
                        Copyright© 2024 ShillGuard. All rights reserved.
                    </p>
                </div>
            </div>
            {/* <div>
                <Banner />
            </div> */}
        </div>
    )
}

export default Footer;