import React from "react";
import MainNavLinks from "./MainNav";

const DesktopNav = () => {
    return (
        <div className="desktop-nav">
            <MainNavLinks />
        </div>
    )
}

export default DesktopNav;