import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import React, { useState, lazy, Suspense, useEffect } from "react";
import Home from './components/HomePage';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import { ThemeContext } from './contexts/theme-context';
import { getDefaultTheme } from './Utils/utils';
import TokenDetails from './components/TokenDetailsPage';
import MarqueeComponent from './components/UI/MarqueeComponent';
import AdSlider from './components/UI/AdSlider';
import Banner from './components/UI/AdBanner';
import ReactGA from "react-ga4";
import BannerRotator from './components/UI/BannerRotator';
import * as httpService from './services/HTTPService';
const TRACKING_ID = "G-FZSWG6ZVH9"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);
// import SocialLinks from './components/SocialLinks';

//import NotFound from './components/NotFound';
// import TokenDetails from './components/TokenDetails';
//const TokenDetails = lazy(() => import('./components/TokenDetailsPage'));
const NotFound = lazy(() => import('./components/NotFoundPage'));
const About = lazy(() => import('./components/AboutPage'));
const News = lazy(() => import('./components/NewsPage'));
const OurVision = lazy(() => import('./components/OurVisionPage'));
const OurVisionTwo = lazy(() => import('./components/OurVisionPage2'));
const Partners = lazy(() => import('./components/PartnersPage'));
const Calls = lazy(() => import('./components/CallsPage'));
const WhyShillguard = lazy(() => import('./components/WhyShillguard'));
const OurToken = lazy(() => import('./components/OurToken'));
const WhatsNext = lazy(() => import('./components/WhatsNext'));
const TermsOfUse = lazy(() => import('./components/TermsOfUsePage'));
const LegalAdvice = lazy(() => import('./components/LegalAdvicePage'));
const TeamPage = lazy(() => import('./components/TeamPage'));
const RoadMap = lazy(() => import('./components/RoadMapPage'));
const StakingPage = lazy(() => import('./components/StakingPage'));

const App = () => {
  const [theme, setTheme] = useState(getDefaultTheme());
  let [tokens, setTokens] = useState([]);
  let [requested, setRequested] = useState(false);
  const velocity = window.innerWidth / 40;
  const isCallsPage = (window.location.href.indexOf('/calls') !== -1 && window.location.href.indexOf('/tokens') === -1) || window.location.pathname === '/';
  const callPageClass = isCallsPage ? 'calls-page' : '';
  const isMarqueeVisible = window.location.href.indexOf('/calls') !== -1 && window.location.href.indexOf('/tokens') === -1;
  //const [hiddenTopAd, setHiddenTopAd] = useState('');
  //const callPageClass = '';
  useEffect(() => {
    const pathName = window.location.pathname.split('/')[1];
    const title = pathName ? pathName + ' - Shillguard' : 'Shillguard';
    document.title = title;
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search});
    if(window.innerWidth < 901) {
      document.getElementById('root').classList.add('mobile-app');
      document.getElementById('root').classList.remove('desktop-app');
    }
    else {
      document.getElementById('root').classList.add('desktop-app');
      document.getElementById('root').classList.remove('mobile-app');
    }

  }, []);

  useEffect(() => {
    if(isCallsPage) {
      // setTimeout(() => {
      //   localStorage.removeItem('top5Tokens');
      //   localStorage.removeItem('top5Callers');
      // }, 1000);
      const el = document.querySelector('.app-header');
      const marqueeSlider = document.querySelector('.marq-slider');
      const onScroll = () => {
        const topGrids = document.querySelector('.top-grids');
        if (window.scrollY === 0) {
          //setHiddenTopAd('');
          if(el) {
            el.classList.remove('hidden-top-ads');
          }
          if(marqueeSlider) {
            marqueeSlider.classList.remove('marq-hidden-top-ads');
          }
          if(topGrids) {
            topGrids.classList.remove('topGrids-hidden-top-ads');
          }
        }
        else {
          if(el) {
            el.classList.add('hidden-top-ads');
          }
          if(marqueeSlider) {
            marqueeSlider.classList.add('marq-hidden-top-ads');
          }
          if(topGrids) {
            topGrids.classList.add('topGrids-hidden-top-ads');
          }
          //setHiddenTopAd('hidden-top-ads');
        }
        
      }
      document.addEventListener('scroll', onScroll);
      return () => document.removeEventListener('scroll', onScroll);
    }
  }, []);

  useEffect(()=> {

    if(isMarqueeVisible) {
      if(!requested) {
        requested = true;
        setRequested(true);
        httpService.getTopTrendingTokens().then((res) => {
          setTokens(res);
        })
      }
    }
  }, [])

  return (
    <ThemeContext.Provider value={{ theme, setTheme }}>
      <div className='top-ads'>
        {/* {isCallsPage ? <Banner /> : null} */}
        {isCallsPage ? (
          <div>
            {/* <a href="https://coinsult.net/" target='_blank'>
              <img alt="top_banner" src="/img/Banner_Coinsult.png" loading="lazy" />
            </a> */}
            {/* <BannerRotator 
                rotatorInterval={10000} 
                //imgArray={[{src: "/img/Banner_Coinsult.png", href: "https://coinsult.net/", alt: "coinsult banner" }, {src: "/img/Banner_Pulse_AI.png", href: "https://pulseai.info", alt: "pulseai banner" }]}
                imgArray={[{src: "/img/three.JPG", href: "http://threeprotocol.ai/", alt: "top banner" }]} 
            /> */}
          </div>
        ) : null}
      </div>
      <div className={`theme-container theme-${theme} ${callPageClass}`}>
        <Header />
        {isMarqueeVisible ? (
          <div className="marq-slider tr-tokens flex-box">
              <div className='marq-bg'>
                <div className='marq-inner'>
                  <h3>TRENDING <br />TOKENS</h3>
                  <MarqueeComponent source={tokens} velocity={velocity} minScale={0.7} resetAfterTries={200}> </MarqueeComponent>
                </div>
              </div>
          </div>
        ) : null}
        
        {/* {isCallsPage ? (
          <div className='adslider-outer'>
            <AdSlider />
          </div>
        ) : null} */}
        <div className='main-out'>
          <div className='main-l'>
            <div className='main-l-t'>
              {/* <img alt="Left_top_bg" src="/img/Left_top_960x1700.png" loading="lazy" /> */}
            </div>
            <div className='main-l-m'>
              {/* <img alt="Left_top_bg" src="/img/Left_mid_960x1500.png" loading="lazy" /> */}
            </div>
          </div>
          <div className='main-content'>
            <Suspense fallback={<div className="container">Loading...</div>}>
              <Router>
                <Routes>
                  {/* routes go here, as children */}
                  <Route path="/" element={<Home />} />
                  <Route path="/about" element={<About />} />
                  <Route path="/news" element={<News />} />
                  <Route path="/roadmap" element={<RoadMap />} />
                  <Route path="/staking" element={<StakingPage />} />
                  <Route path="/team" element={<TeamPage />} />
                  <Route path="/WhyShillguard" element={<WhyShillguard />} />
                  <Route path="/ourtoken" element={<OurToken />} />
                  <Route path="/whatsnext" element={<WhatsNext />} />
                  <Route path="/vision" element={<OurVision />} />
                  <Route path="/vision2" element={<OurVisionTwo />} />
                  <Route path="/partners" element={<Partners />} />
                  <Route path="/calls" element={<Calls />} />
                  <Route path="/terms" element={<TermsOfUse />} />
                  <Route path="/legal-advice" element={<LegalAdvice />} />
                  <Route path="/tokens/:tokenName" element={<TokenDetails />} />
                  <Route path="*" element={<NotFound />} />
                </Routes>
              </Router>
            </Suspense>
          </div>
          <div className='main-r'>
            <div className='main-r-t'>
              {/* <img alt="Right_top_bg" src="/img/Right_top_960x1700.png" loading="lazy" /> */}
            </div>
            <div className='main-r-m'>
              {/* <img alt="Right_top_bg" src="/img/Right_mid_960x1500.png" loading="lazy" /> */}
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </ThemeContext.Provider>
  );
};

export default App;