import React from "react";
import SvgIcon from "../components/UI/SVGIcon";

const isNumber = (char) => {
    return /^\d$/.test(char);
}

const customSort = (valueA, valueB, nodeA, nodeB, isDescending) => {
    if(valueA !== null && valueB !== null) {
        // First: check the values at the end of the string for char like % or k... etc. and remove it
        const lastvalueACharIsNotNumber = !isNumber(valueA[valueA.length - 1]);
        const lastvalueBCharIsNotNumber = !isNumber(valueB[valueB.length - 1]);

        let a = valueA;
        //console.log(valueA.charAt(valueA.length - 1))
        //console.log(isNumber(valueA[valueA.length - 1]))
        let b = valueB;
        
        // remove the last char if is not integer
        if(lastvalueACharIsNotNumber) {
            a = a.slice(0, -1);
        }
        if(lastvalueBCharIsNotNumber) {
            b = b.slice(0, -1);
        }
        // remove '+' at the begining
        if(a.charAt(0) === '+') {
            a = a.slice(1);
        }
        let aNum = parseFloat(a);
        if(b.charAt(0) === '+') {
            b = b.slice(1);
        }
        let bNum = parseFloat(b);
        if (aNum === bNum) return 0;
        else if (aNum === null)  // a is null? last 
        return 1;
        else if (bNum === null)  // b is null? last
        return -1;
        return (aNum > bNum) ? 1 : -1;
    }
    
}

const CallEfHtml = (props) => {
    //AVG_TracingImpact
    let callVal = props.data.AVG_CallImmediateEffect;
    if(typeof(callVal) === 'string') {
        let sliced = callVal.slice(0, callVal.length - 1);
        sliced = parseFloat(sliced);
        //traceVal = sliced;
    }
    return (
        <span>{callVal}</span>
    )
}

const BuyLinkHtml = (props) => {
    return (
        <div>
            {props !== undefined ? (
                <div className="flex-box custom-cell">
                    <div className="ellipsis accent-txt1" style={{'cursor': 'pointer'}}>
                        {/* <a className="ellipsis accent-txt1" href={props.data.BuyLink} style={{'textDecoration': 'none'}} target="_blank" rel="noreferrer">{props.data.BuyLink}</a> */}
                        <a className="ellipsis btn btn-primary" href={props.data.BuyLink} style={{'textDecoration': 'none'}} target="_blank" rel="noreferrer">Buy</a>
                    </div>
                    {/* <div title="Copy Link"><SvgIcon copyText={props.data.BuyLink} /></div> */}
                </div>
            ) : null}
        </div>
    )
}

const TracingImpactHtml = (props) => {
    const label = props.data.SuccessRate;
    const txtColor = label[0] === '-' ? 'red-value-label' : 'green-value-label';
    return (
        <div>
            {props !== undefined ? (
                <div className={txtColor}>{props.data.SuccessRate}</div>
            ) : null}
        </div>
    )
}

const RedOrGreenColoringHtml = (props) => {
    const label = props.data.AVG_TracingImpact;
    const txtColor = label[0] === '-' ? 'red-value-label' : 'green-value-label';
    return (
        <div>
            {props !== undefined ? (
                <div className={txtColor}>{props.data.AVG_TracingImpact}</div>
            ) : null}
        </div>
    )
}


const RedOrGreenColoringPeak = (props) => {
    const label = props.data.AVG_FromCallToPeak;
    const txtColor = label[0] === '-' ? 'red-value-label' : 'green-value-label';
    return (
        <div>
            {props !== undefined ? (
                <div className={txtColor}>{props.data.AVG_FromCallToPeak}</div>
            ) : null}
        </div>
    )
}

const RedOrGreenColoringCallEf = (props) => {
    const label = props.data.AVG_CallImmediateEffect;
    const txtColor = label[0] === '-' ? 'red-value-label' : 'green-value-label';
    return (
        <div>
            {props !== undefined ? (
                <div className={txtColor}>{props.data.AVG_CallImmediateEffect}</div>
            ) : null}
        </div>
    )
}


const TokenContractHtml = (props) => {
    return (
        <div>
            {props !== undefined ? (
                <div className="flex-box custom-cell">
                    <div className="ellipsis accent-txt1">{props.data.TokenHash}</div>
                    <div title="Copy Link"><SvgIcon copyText={props.data.TokenHash} /></div>
                </div>
            ) : null}
        </div>
    )
}

const CallGroupImageHtml = (props) => {
    let imageBase64str;
    const folderName = '/callgrimages/';
    let imgUrl = '';
    if(props.data.CryptoGroupImage || props.data.Image || props.data.TokenImage) {
        let imgSrc = props.data.CryptoGroupImage || props.data.Image || props.data.TokenImage;
        imageBase64str = imgSrc.replace(/(\r\n|\n|\r)/gm, "");
        if(imageBase64str && imageBase64str !== '' && !imageBase64str.startsWith('http')) {
            imageBase64str = "data:image/png;base64, " + imgSrc.replace(/(\r\n|\n|\r)/gm, "");
        }
        if(imageBase64str.startsWith('http')) {
            imageBase64str = imgSrc;
        }
        if(imgSrc === null || imageBase64str.startsWith('https://shillguard-001')) {
            // TOP 5 Callers
            const imgName = imgSrc.split('/').pop();
            const img = folderName + imgName;
            imageBase64str = img;
        }
    }
    
    return (
        <div>
            {props !== undefined ? (
                <div className={props.CryptoGroupImage ? 'flex-box custom-cell rounded-img' : 'custom-cell rounded-img'} >
                    {imageBase64str? (<img height={46} src={imageBase64str} alt="CallGroupImage" />) : null}
                    {/* {props.data.Image ? (<img height={27} src={props.data.Image} alt="CallGroupImage" />) : null} */}
                </div>
            ) : null}
        </div>
    )
}

const top5NumberLabel = (data) => {
    return `#${data.node.rowIndex + 1}`;
}

const TOP5CallersName = (props) => {
    return props.data.Name;
}

export const gridColumns = [

    // Home Page
    // Common columns:
    {
        field: 'NameOfCallGroup', 
        headerName: 'Call Group', 
        resizable: true,
        headerTooltip: 'The name of the group or individual influencer responsible for making the call.',
    },
    // Token Details page
    // Common columns:
    {field: 'number', valueGetter: "node.rowIndex + 1", headerName: "#", width: 60, sortable: false, suppressSorting: true},
    {
        field: 'Top5TokensImage', 
        headerName: 'Image', 
        resizable: true,
        width: 50,
        headerTooltip: 'The image of the cryptocurrency token.',
        cellRenderer: CallGroupImageHtml,
    },
    {
        field: 'CryptoGroupImage', 
        headerName: 'Image', 
        resizable: true,
        width: 100,
        headerTooltip: 'The image of the group or individual influencer responsible for making the call.',
        cellRenderer: CallGroupImageHtml,
    },
    {
        field: 'TokenName', 
        headerName: 'Token', 
        resizable: true,
        headerTooltip: 'The name of the cryptocurrency token being called last.',
    },
    //OrderNumber
    {
        field: 'OrderNumber', 
        headerName: 'OrderNumber', 
        resizable: true,
        headerTooltip: 'The name of the cryptocurrency token being called last.',
    },
    {
        field: 'BuyLink', 
        headerName: 'Buy', 
        resizable: true, 
        width: 120,
        enableCellTextSelection: true,
        headerTooltip: 'A link to a platform where users can purchase the mentioned token.',
        cellRenderer: BuyLinkHtml,
    },
    {
        field: 'TotalMarketCapIN', 
        headerName: 'CallMktCap', 
        resizable: true,
        width: 120,
        headerTooltip: 'The market capitalization of the token at the time of the call.',
        comparator: customSort
    },
    {
        field: 'TotalMarketCap', 
        headerName: 'MktCap', 
        resizable: true, 
        width: 120,
        headerTooltip: 'The current market capitalization of the token.' ,
        comparator: customSort
    },
    {
        field: 'ImpactOfCall', 
        headerName: 'CallEfc', 
        resizable: true, 
        width: 120,
        headerTooltip: 'Maximal positive  market capitalization change  of the token in the first 15 minutes after the call was made.' ,
        comparator: customSort
    },
    {
        field: 'SuccessRate', 
        headerName: 'Trace', 
        resizable: true,
        width: 120,
        headerTooltip: "An ongoing assessment of the token's market capitalization movement and impact since the call.",
        cellRenderer: TracingImpactHtml,
        comparator: customSort
    },
    {
        field: 'FromCalltoPeak', 
        headerName: 'Peak',
        resizable: true,
        width: 120,
        headerTooltip: "The percentage change in price from the time of the call to the token's all-time high (ATH).",
        comparator: customSort
    },
    // Extra Columns:
    {
        field: 'TokenHash', 
        headerName: 'Token Contract', 
        resizable: true,
        headerTooltip: "The unique cryptographic identifier associated with the token on the blockchain.",
        cellRenderer: TokenContractHtml,
    },
    {
        field: 'Chain', 
        headerName: 'Chain', 
        resizable: true,
        headerTooltip: "Chain The blockchain on which the cryptocurrency token operates."
    },
    {
        field: 'PriceUSD', 
        headerName: 'Price', 
        resizable: true,
        headerTooltip: "Current price of the token in USD.",
    },
    {
        field: 'VolumeGenereted_h24', 
        headerName: 'Volume(24)', 
        resizable: true,
        headerTooltip: "The trading volume of the token.",
    },
    // {field: 'ATX', headerName: 'ATX', resizable: true },
    {
        field: 'Holders', 
        headerName: 'Holders', 
        resizable: true,
        headerTooltip: "The number of token holders or addresses holding the cryptocurrency.",
    },
    {
        field: 'CryptoTokenCall', 
        headerName: 'Call Description', 
        resizable: true,
        headerTooltip: "Contains the text or description of the cryptocurrency call made by the group or influencer.",
        tooltipField: 'CryptoTokenCall',
    },
    {
        field: 'Source', 
        headerName: 'Source', 
        resizable: true,
        headerTooltip: "Platform or channel from which the cryptocurrency call or recommendation originated.",
        // headerComponentParams: {
        //     template: infoHeaderTemplate()
        // }
    },
    {
        field: 'Timestamp', 
        headerName: 'Date', 
        resizable: true,
        headerTooltip: "Year/Month/Day/UTC time of the call.",
    },
    {field: 'Top5Number', valueGetter: top5NumberLabel, headerName: "#", width: 40, sortable: false, suppressSorting: true},
    {
        field: 'Image', 
        headerName: 'Image', 
        resizable: true,
        width: 50,
        headerTooltip: 'The image of the group or individual influencer responsible for making the call.',
        cellRenderer: CallGroupImageHtml,
    },
    {
        field: 'Name', 
        headerName: 'Name', 
        resizable: true,
        width: 100,
        headerTooltip: 'The name of the cryptocurrency token being called last.',
    },
    {
        field: 'TOP5CallersName', 
        headerName: 'Name', 
        resizable: true,
        width: 200,
        headerTooltip: 'The name of the cryptocurrency caller being called last.',
        cellRenderer: TOP5CallersName,
    },
    {
        field: 'BuyLinkTop5', 
        headerName: 'Buy', 
        resizable: true, 
        width: 113,
        enableCellTextSelection: true,
        headerTooltip: 'A link to a platform where users can purchase the mentioned token.',
        cellRenderer: BuyLinkHtml,
    },
    {
        field: 'AVG_CallImmediateEffect', 
        headerName: 'CallEfc', 
        resizable: true, 
        width: 85,
        headerTooltip: 'Maximal positive  market capitalization change  of the token in the first 15 minutes after the call was made.' ,
        comparator: customSort,
        cellRenderer: RedOrGreenColoringCallEf,
    },
    {
        field: 'AVG_FromCallToPeak', 
        headerName: 'Peak', 
        resizable: true, 
        width: 85,
        headerTooltip: "The percentage change in price from the time of the call to the token's all-time high (ATH).",
        comparator: customSort,
        cellRenderer: RedOrGreenColoringPeak
    },
    {
        field: 'AVG_TracingImpact', 
        headerName: 'Trace', 
        resizable: true, 
        width: 85,
        headerTooltip: "An ongoing assessment of the token's market capitalization movement and impact since the call.",
        comparator: customSort,
        cellRenderer: RedOrGreenColoringHtml,
    }
]