import React, { useState, useEffect } from "react";
import { getDefaultTheme } from "../../Utils/utils";

const SvgIcon = (props) => {
    const contrastColor = getDefaultTheme() === 'light' ? '#181A20' : '#ffffff';
    const themeColorClass = getDefaultTheme() === 'light' ? 'light-color' : 'dark-color';
    const iconColor = '#00c8fb';

    const [color, setColor] = useState(iconColor);
    const [displayProp, setDisplayProp] = useState('none');
    const changeIconColor = () => {
        const currentColor = iconColor;
        setColor(contrastColor);
        setTimeout(() => {
            setColor(currentColor);
        }, 1500)
    }
    const showMsg = () => {
        setDisplayProp('block');
        setTimeout(() => {
            setDisplayProp('none');
        }, 1000)
    }
    const svgOnCLick = () => {
        const text = props.copyText;
        if(text) {
            navigator.clipboard.writeText(text).then(() => {
                console.log('Content copied to clipboard');
                changeIconColor();
                showMsg();
                /* Resolved - text copied to clipboard successfully */
              },() => {
                console.error('Failed to copy');
                /* Rejected - text failed to copy to the clipboard */
              });
        }
    }

    useEffect(() => {
        setColor('#00c8fb');
    }, [])

    return (
        <div style={{'position': 'relative'}} className={themeColorClass}>
            <svg  onClick={svgOnCLick} fill={color} style={{'cursor': 'pointer'}} xmlns="http://www.w3.org/2000/svg" width="17" height="17" className="bi bi-link" viewBox="0 0 17 17"> <path d="M6.354 5.5H4a3 3 0 0 0 0 6h3a3 3 0 0 0 2.83-4H9c-.086 0-.17.01-.25.031A2 2 0 0 1 7 10.5H4a2 2 0 1 1 0-4h1.535c.218-.376.495-.714.82-1z"></path> <path d="M9 5.5a3 3 0 0 0-2.83 4h1.098A2 2 0 0 1 9 6.5h3a2 2 0 1 1 0 4h-1.535a4.02 4.02 0 0 1-.82 1H12a3 3 0 1 0 0-6H9z"></path> </svg>
            <div className="copied-popup" style={{'display': displayProp}}><span>Copied to clipboard!</span></div>
        </div>    
    )
}

export default SvgIcon;