import React from "react";

const MainNavLinks = () => { 

    return (
        <div className="main-nav">
            <nav>
                <ul>
                    {/* <li>
                        <a href="/">
                            Home
                        </a>
                    </li> */}
                    <li>
                        <a href="/calls">
                            Call Group & Signals
                        </a>
                    </li>
                    <li>
                        <a href="/news">
                            Crypto News
                        </a>
                    </li>
                    <li>
                        <a href="/about">
                            About Us
                        </a>
                    </li>
                    <li>
                        <a href="/roadmap">
                            RoadMap
                        </a>
                    </li>
                    <li>
                        <a href="/ourtoken">
                            Our Token
                        </a>
                    </li>
                    <li>
                        <a href="/staking">
                            Staking
                        </a>
                    </li>
                    {/* <li>
                        <a href="/whyshillguard">
                            Why Shillguard 
                        </a>
                    </li> */}
                    {/* <li>
                        <a href="/whatsnext">
                            What's Next
                        </a>
                    </li> */}
                    {/* <li>
                        <a href="/vision">
                            Our Vision and Road Ahead
                        </a>
                    </li> */}
                    {/* <li>
                        <a href="/vision2">
                            Our Vision and Road Ahead 2
                        </a>
                    </li> */}
                    <li>
                        <a href="/team">
                            Team
                        </a>
                    </li>
                    <li>
                        <a href="/partners">
                            Partners
                        </a>
                    </li>
                </ul>
            </nav>
            

            {/* <a href="/">
                <span role="img" aria-label="about us">💁🏻‍♂️</span>
                About us
            </a>
            <a href="/">
                <span role="img" aria-label="price">💸</span>
                Pricing
                </a>
            <a href="/">
                <span role="img" aria-label="contact">📩</span>
                Contact
            </a> */}
        </div>
    )
}

export default MainNavLinks;

// const App = () => {
//   const [open, setOpen] = React.useState(false);
//   const node = React.useRef();
//   return (
//     <div>
//       <div>
//         <h1>Hello. This is burger menu tutorial</h1>
//         <img src="https://image.flaticon.com/icons/svg/2016/2016012.svg" alt="burger icon" />
//         <small>Icon made by <a href="https://www.freepik.com/home">Freepik</a> from <a href="https://www.flaticon.com">www.flaticon.com</a></small>
//        </div>
//       <div ref={node}>
//         <Burger open={open} setOpen={setOpen} />
//         <Menu open={open} setOpen={setOpen} />
//       </div>
//     </div>
//   )  
// }


// ReactDOM.render(<App />, document.getElementById('app'));

// const useOnClickOutside = (ref, handler) => {
//   React.useEffect(() => {
//     const listener = event => {
//       if (!ref.current || ref.current.contains(event.target)) {
//         return;
//       }
//       handler(event);
//     };
//     document.addEventListener('mousedown', listener);

//     return () => {
//       document.removeEventListener('mousedown', listener);
//     };
//   },
//   [ref, handler],
//   );
// };