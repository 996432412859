import React from "react";
import DesktopNav from "./DesktopNav";
import MobileNav from "./MobileNav";
import LogoAndCompName from "./UI/LogoAndCompName";
import HeaderSocialLinks from "./HeaderSocialLinks";
import { headerSocialLinksArray } from "../data/HeaderSocialLinksData";

const Header = () => {
    
    return (
        <div className="header-outer">
            <div className="app-header">
                <div className="flex-box">
                    <LogoAndCompName />
                    <div className="nav-outer">
                        <DesktopNav />
                        <MobileNav />
                    </div>
                    <div className="header-right">
                        <HeaderSocialLinks links={headerSocialLinksArray} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Header;